html,
body {
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;
  font-size: 14px;
  color: black;
  word-break: normal;
  word-wrap: break-word;
  font-family: "Spoqa Han Sans Neo";
}

p,
label {
  margin: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
}

* {
  outline: none;
  font-family: inherit;
  box-sizing: border-box;
}

input:disabled {
  -webkit-text-fill-color: currentColor;
  opacity: 1;
}
